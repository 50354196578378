import {Route, Routes, Link } from 'react-router-dom'
import './style.home.css'
import "./Glavniy/styles.css"
import HomesIndex from "./Glavniy/HomesIndex";



function App() {
  return (
    <div className="App">
       <HomesIndex/>
    </div>
  );
}

export default App;



// <div className="row  bg-info">
//     <div className="col-md-3 ">
//         <Link to="/" className={'mx-3 text-white '}>Home</Link>
//         <Link to="/CenterPage" className={' text-white '}>Buy</Link>
//         <Link to="" className={'mx-3 text-white '}>Footer</Link>
//     </div>
// </div>
// <div className="container">
//     <div className="row">
//         <div className="col-md-12">
//             <Routes>
//                 <Route path={'/'} element={<HomePage/>} />
//                 <Route path={'/CenterPage'} element={<CenterPage/>} />
//             </Routes>
//         </div>
//     </div>
// </div>