import React from 'react';
import {Route, Routes} from "react-router-dom";
import Homes from "./Homes/Homes";

function HomesIndex(props) {
    return (
        <>

           

            <Routes>

                <Route path={'/'} element={<Homes/>}/>

            </Routes>

        </>
    );
}

export default HomesIndex;