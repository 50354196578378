import React from 'react';
// import video1 from "../Video/video1.mp4"
// import { Link } from "react-router-dom";
// import rasm1 from "../Rasm/1612691869_170-p-zelenii-fon-khimiya-245.jpg"
import BASEAPK from "../Video/base.apk"
function Homes(props) {
    return (
        <>
            <section className="home" id="home">
                <div className="div-backgoround"></div>
                <div className="content">

                    <h1>ТЕОРЕТИЧЕСКИЕ ОСНОВЫ</h1>
                    <p>ТЕОРЕТИЧЕСКИЕ ОСНОВЫ ОТДЕЛКИ
                        ВОЛОКНИСТЫХ МАТЕРИАЛОВ</p>
                    <h1><i>Учебник</i></h1>
                    <p><i>(под редакцией проф. А.М.Киселева)</i></p>

                    <div className="div-btn">

                        <a href={BASEAPK} download className={'spes spes1'}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            
                            Yuklab olish
                            <i class="fas fa-file-download"></i>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.abdulloh_foziljonov.playexample" className={'spes '}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            google play
                            <i class="fas fa-play"></i>
                        </a>
                    </div>


                </div>

            </section>

{/* 
            <section style={{ display: "none" }} className="about" id="about">

                <h1 className="heading"><i className="fas fa-quote-left"></i> 5-binoga nazar <i
                    className="fas fa-quote-right"></i></h1>

                <div className="row">

                    <div className="image">
                        <video src={video1} controls alt="" />
                    </div>

                    <div className="content">
                        <h3>Eng ahil oiladek jamoa</h3>
                        <p>TTYESI O'zbekiston hududida o'z mavqeyiga ega insitutlardan bo'lib
                            ancha yillik faoliyati davomida qanchadan-qancha tajribali o'qtuvchilar, professorlar,
                            talabalarni yetishtirib chiqargan
                        </p>
                        <p>Insitutimizda 5-Bino  talabalari va domlalari o'zlarining ilimlari, fidoyiliklari,
                            salohiyatlari bilan ajralib turadilar, kafedra 1959-yilda Yuqori Molekulyar birikmalarning
                            kimyoviy texnalogiya nomi bilan tashkil etilgan bo'ib uning tarkibiga muamolar labaratoriyasi ham kirgan.
                            Asoschisi Maqsuda Agzamovna Hadjieva edi. Hozirgi kunda kafedra nomi "Kimyoviy texnalogiya"

                        </p>
                        <Link to={""}>
                            <button className="btn">Batafsil...</button>
                        </Link>
                    </div>

                </div>

                <div className="box-container">

                    <div className="box one-1">
                        <i className="fas fa-users"></i>
                        <h3>1000+</h3>
                        <p>Talabalar soni</p>
                    </div>

                    <div className="box one-2">
                        <i className="fas fa-tree"></i>
                        <h3>100*100 metr</h3>
                        <p>O'quv binosi</p>
                    </div>

                    <div className="box one-3">
                        <i className="fas fa-paw"></i>
                        <h3>25+</h3>
                        <p>Domlalarimiz soni</p>
                    </div>

                    <div className="box one-4">
                        <i className="fas fa-donate"></i>
                        <h3>102+</h3>
                        <p>Yutuqlarimiz soni</p>
                    </div>

                </div>

            </section> */}


            {/* <section className="service" style={{ display: "none" }} id="service">

                <h1 className="heading"><i className="fas fa-quote-left"></i> Bizdagi xizmatlar <i
                    className="fas fa-quote-right"></i></h1>

                <div className="box-container">

                    <div className="box">
                        <i className="fas fa-seedling"></i>
                        <h3>qog'oz</h3>
                        <p>Mahaliy hom-ashyolardan qog'oz ishlab chiqarish</p>
                        <a href="#">read more</a>
                    </div>

                    <div className="box">
                        <i className="fas fa-recycle"></i>
                        <h3>Aralash tola</h3>
                        <p>Aralash tolali materiallarni kimyoviy pardozlash</p>
                        <a href="#">read more</a>
                    </div>

                    <div className="box">
                        <i className="fas fa-hand-holding-water"></i>
                        <h3>modifikatsiyalash</h3>
                        <p>Kimyoviy tolalarni <br /> modifikatsiyalash</p>
                        <a href="#">read more</a>
                    </div>

                    <div className="box">
                        <i className="fas fa-tree"></i>
                        <h3>Tabiy ipak</h3>
                        <p>Tabiy ipakni kimyoviy pardozlash</p>
                        <a href="#">read more</a>
                    </div>

                    <div className="box one-2">
                        <i className="fas fa-paw"></i>
                        <h3>Mahalliy tola</h3>
                        <p>Mahalliy tolali kimyovoy pardozlashnimg inovatsion <br /> yollari</p>
                        <a href="#">read more</a>
                    </div>

                    <div className="box one-3">
                        <i className="fas fa-solar-panel"></i>
                        <h3>Talabalar</h3>
                        <p>Iqtidorli talabalarni ilmiy ishlariga yordamlashib ularga yo'l-yo'rug' ko'rsatish</p>
                        <a href="#">read more</a>
                    </div>

                </div>

            </section> */}

            {/* 
            <section className="project" id="project">

                <h1 className="heading"><i className="fas fa-quote-left"></i> Bizning loyihalarimiz <i
                    className="fas fa-quote-right"></i></h1>

                <div className="box-container">

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style="--i:1;" href="#" class="fas fa-heart"></a>*/}
            {/*<a style="--i:2;" href="#" class="fas fa-share"></a>*/}
            {/*<a style="--i:3;" href="#" class="fas fa-comment"></a>*/}
            {/* </div>

                    </div>

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style="--i:1;" href="#" class="fas fa-heart"></a>*/}
            {/*<a style="--i:2;" href="#" class="fas fa-share"></a>*/}
            {/*<a style="--i:3;" href="#" class="fas fa-comment"></a>*/}
            {/* </div>
                    </div>

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style="--i:1;" href="#" class="fas fa-heart"></a>*/}
            {/*<a style="--i:2;" href="#" class="fas fa-share"></a>*/}
            {/*<a style="--i:3;" href="#" class="fas fa-comment"></a>*/}
            {/* </div>
                    </div>

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style="--i:1;" href="#" class="fas fa-heart"></a>*/}
            {/*<a style="--i:2;" href="#" class="fas fa-share"></a>*/}
            {/*<a style="--i:3;" href="#" class="fas fa-comment"></a>*/}
            {/* </div>
                    </div>

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style="--i:1;" href="#" class="fas fa-heart"></a>*/}
            {/*<a style="--i:2;" href="#" class="fas fa-share"></a>*/}
            {/*<a style="--i:3;" href="#" class="fas fa-comment"></a>*/}
            {/* </div>
                    </div>

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style={"--i:1;"} href="#" class="fas fa-heart"></a>*/}
            {/*<a style={"--i:2;"} href="#" class="fas fa-share"></a>*/}
            {/*<a style={"--i:3;"} href="#" class="fas fa-comment"></a>*/}
            {/* </div>
                    </div>

                    <div className="box">
                        <img src={rasm1} alt=""/>
                        <div className="icons">
                            <p>Erkinov Abdurahmon</p> */}
            {/*<a style={"--i:1;"} href="#" class="fas fa-heart"></a>*/}
            {/*<a style={"--i:2;"} href="#" class="fas fa-share"></a>*/}
            {/*<a style={"--i:3;"} href="#" class="fas fa-comment"></a>*/}
            {/* </div>
                    </div>

                </div>

            </section> */}

            {/* 
            <section className="donate" id="donate">

                <h1 className="heading"><i className="fas fa-quote-left"></i> donate us <i
                    className="fas fa-quote-right"></i></h1>

                <div className="video-container">
                    <video src="images/video.mp4" loop autoPlay muted></video>
                </div>

                <div className="form-container">

                    <form action="">

                        <div className="inputBox">
                            <input type="text" placeholder="first name"/>
                            <input type="text" placeholder="last name"/>
                        </div>

                        <div className="inputBox">
                            <input type="email" placeholder="enter your email"/>
                            <select name="" id="">
                                <option value="" selected disabled>donation amount</option>
                                <option value="$5">$5</option>
                                <option value="$10">$10</option>
                                <option value="$15">$15</option>
                                <option value="$20">$20</option>
                            </select>
                        </div>

                        <textarea name="" id="" cols="30" rows="10" placeholder="message ( optional )"></textarea>

                        <input type="submit" className="btn"/>

                    </form>

                </div>

            </section> */}


            {/* <section className="post" id="post">

                <h1 className="heading"><i className="fas fa-quote-left"></i> our recent posts <i
                    className="fas fa-quote-right"></i></h1>

                <div className="box-container">

                    <div className="box">
                        <img src="images/img1.jpg" alt=""/>
                        <div className="content">
                            <span> <i className="fas fa-calendar"></i> jan 2, 2021 </span>
                            <h3>post title goes here</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo veritatis nostrum voluptates
                                cumque deserunt incidunt, minus mollitia rem molestiae ad.</p>
                            <a href="#">
                                <button className="btn">read more</button>
                            </a>
                        </div>
                    </div>

                    <div className="box">
                        <img src="images/img3.jpg" alt=""/>
                        <div className="content">
                            <span> <i className="fas fa-calendar"></i> jan 3, 2021 </span>
                            <h3>post title goes here</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo veritatis nostrum voluptates
                                cumque deserunt incidunt, minus mollitia rem molestiae ad.</p>
                            <a href="#">
                                <button className="btn">read more</button>
                            </a>
                        </div>
                    </div>

                    <div className="box">
                        <img src="images/img6.jpg" alt=""/>
                        <div className="content">
                            <span> <i className="fas fa-calendar"></i> jan 8, 2021 </span>
                            <h3>post title goes here</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo veritatis nostrum voluptates
                                cumque deserunt incidunt, minus mollitia rem molestiae ad.</p>
                            <a href="#">
                                <button className="btn">read more</button>
                            </a>
                        </div>
                    </div>

                </div>

            </section> */}


            {/* <section className="footer">

                <div className="box-container">

                    <div className="box">
                        <a href="#" className="logo"><i className="fas fa-seedling"></i>green</a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda possimus impedit voluptate
                            fuga id sapiente perspiciatis amet eos esse iure.</p>
                    </div>

                    <div className="box">
                        <h3 className="share">share</h3>
                        <a href="#">facebook</a>
                        <a href="#">twitter</a>
                        <a href="#">instagram</a>
                        <a href="#">github</a>
                    </div>

                    <div className="box">
                        <h3 className="letter">newsletter</h3>
                        <form action="">
                            <input type="email" placeholder="enter your email"/>
                            <button type="submit" className="fas fa-paper-plane"></button>
                        </form>
                    </div>

                </div>

                <h1 className="credit">created by <span>mr. web designer</span> | all rights reserved. </h1>

            </section> */}


        </>
    );
}

export default Homes;